import {
  Button,
  Flex,
  FormLabel,
  Icon,
  IconButton,
  Image,
  Progress,
  Text,
  Tooltip,
  useToast
} from '@chakra-ui/react'
import { useAuthContext } from 'context/AuthProvider'
import { saveAs } from 'file-saver'
import { useField } from 'formik'
import _ from 'lodash'
import * as React from 'react'
import { Check, File, X } from 'react-feather'
import { HiDownload, HiInformationCircle } from 'react-icons/hi'
import { get, SpaceProps } from 'styled-system'
import { ERROR_TOAST } from '../../../constants'
import { UploadFile } from '../../../generated/graphql'
import { theme } from '../../../theme'
import strapiHelpers from '../../../utils/strapiHelpers'
import { AddFileButton, FileWrapper, HiddenInput, Wrapper } from './styles'

const { colors } = theme

type FileUploaderProps = SpaceProps & {
  name: string
  bg?: string
  placeholder?: string
  label?: string
  onUpload?: (id: string | string[]) => void
  isMulti?: boolean
  isDisabled?: boolean
  showError?: boolean
  tooltip?: string
  fileAccept?: string
  download?: boolean
}

type ProgressObject = {
  state: string
  percentage: number
}

type UploadProgress = {
  [key: string]: ProgressObject
}

const FileUploader: React.FC<FileUploaderProps> = ({
  name,
  placeholder,
  bg,
  label,
  isMulti,
  isDisabled,
  showError,
  tooltip,
  fileAccept,
  download,
  ...rest
}) => {
  const { userRole } = useAuthContext()
  const [stateFiles, setStateFiles] = React.useState<File[]>([])
  const [uploading, setUploading] = React.useState(false)
  const [{ value }, meta, helpers] = useField<UploadFile | UploadFile[]>(name)
  const [uploadProgress, setUploadProgress] = React.useState<UploadProgress>({})
  const isInvalid = meta.touched && meta.error

  const renderProgress = (fileName: string) => {
    const progress = uploadProgress[fileName]
    if (uploading) {
      return (
        <Progress
          left={0}
          right={0}
          bottom={0}
          height="2px"
          color="green"
          hasStripe={true}
          isAnimated={true}
          position="absolute"
          value={progress?.percentage || 0}
        />
      )
    }
  }

  const toast = useToast()

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (files) {
      setStateFiles((prevFiles) => prevFiles?.concat(Array.from(files)))
    }
  }

  const progress = ({ loaded, total }: ProgressEvent, file: File): void => {
    const copy = { ...uploadProgress }
    copy[file.name] = {
      state: 'pending',
      percentage: Math.round((loaded * 100) / total)
    }
    setUploadProgress((prevProgress) => ({ ...prevProgress, ...copy }))
  }

  const handleUpload = async () => {
    try {
      // does value have files of this name
      const names =
        isMulti && Array.isArray(value)
          ? value.map((el) => el.name)
          : (_.get(value, 'name', '') as [])

      const promises = stateFiles
        .filter((file) => !names.includes(file.name))
        .map((file) => strapiHelpers.upload(file, progress))

      setUploading(true)
      const isArray = Array.isArray(value)

      const uploadArr = await Promise.all(promises)
      const uploads = isMulti ? uploadArr.map((upload) => upload.data[0]) : uploadArr[0].data[0]

      const mergedArray =
        isArray && isMulti ? [...(uploads as UploadFile[]), ...(value as UploadFile[])] : uploads

      setStateFiles([])
      helpers.setValue(mergedArray)
      setUploading(false)
    } catch (e) {
      setUploading(false)
      toast({
        description: 'Something went wrong while uploading your file.',
        ...ERROR_TOAST
      })
    }
  }

  function determineType(toBeDetermined: UploadFile | UploadFile[]): toBeDetermined is UploadFile {
    if (toBeDetermined instanceof Array) {
      return false
    }
    return true
  }

  React.useEffect(() => {
    if (isMulti && value && value instanceof Array) {
      setStateFiles(stateFiles.filter((e) => value.some((f) => `${f.name}${f.ext}` !== e.name)))
    } else if (value && determineType(value) && value.name) {
      setStateFiles(stateFiles.filter((e) => e.name.replace(/\.[^/.]+$/, '') !== `${value.name}`))
    }
    // eslint-disable-next-line
  }, [value])
  return (
    <Wrapper mb={4} {...rest}>
      {label && (
        <Flex alignItems="center" justifyContent={tooltip ? 'space-between' : 'flex-start'}>
          <FormLabel fontWeight="600" color={isInvalid ? 'error.500' : 'text'} htmlFor={label}>
            {label}
          </FormLabel>
          {tooltip && userRole !== 'regulatory' ? (
            <Tooltip
              hasArrow
              bg="secondary"
              color="background"
              label={tooltip}
              borderRadius="xl"
              alignItems="center"
            >
              <span>
                <Icon as={HiInformationCircle} color="secondary" />
              </span>
            </Tooltip>
          ) : null}
        </Flex>
      )}
      {value && value instanceof Array && (
        <Wrapper>
          {value.map((file: UploadFile) => {
            return (
              <Flex justify="space-between" key={file.name} alignItems="center">
                <FileWrapper justify="space-between" p={4}>
                  <Flex align="center" w="70%">
                    {file?.mime?.includes('image/') ? (
                      <Image
                        mr={3}
                        rounded="md"
                        width="70px"
                        height="auto"
                        maxH={{ base: '70px', md: 'unset' }}
                        src={file.url}
                      />
                    ) : (
                      <File color={theme.colors.brand[300]} />
                    )}
                    <Text ml={4} isTruncated>
                      {file.name}
                    </Text>
                  </Flex>
                  <Check size={20} color={get(colors, 'green.500', 'green')} />
                </FileWrapper>
                {download && (
                  <IconButton
                    onClick={() => saveAs(file.url, file.name)}
                    variant="link"
                    aria-label="download file"
                    icon={<HiDownload />}
                  />
                )}
              </Flex>
            )
          })}
        </Wrapper>
      )}
      {value && determineType(value) && value.name && (
        <Wrapper>
          <FileWrapper justify="space-between" p={4} key={value.name}>
            <Flex align="center" w="70%">
              {value.mime.includes('image/') ? (
                <Image
                  rounded="md"
                  width="70px"
                  height="auto"
                  maxH={{ base: '70px', md: 'unset' }}
                  src={value.url}
                />
              ) : (
                <File color={theme.colors.brand[300]} />
              )}
              <Text ml={4} isTruncated>
                {value.name}
              </Text>
            </Flex>
            <Check size={20} color={get(colors, 'green.500', 'green')} />
            <Flex
              p={2}
              onClick={() => {
                setStateFiles(stateFiles.filter((file) => file.name !== value.name))
                if (Array.isArray(value)) {
                  helpers.setValue(value.filter((file) => file.name !== value.name))
                } else {
                  helpers.setValue({} as UploadFile)
                }
              }}
            >
              <IconButton size="xs" icon={<X />} aria-label="Delete File" />
            </Flex>
          </FileWrapper>
        </Wrapper>
      )}
      {stateFiles &&
        stateFiles.length > 0 &&
        stateFiles.map((file: File) => {
          const progress = uploadProgress[file.name]
          const isImage = file.type.includes('image/')
          return (
            <>
              <FileWrapper justify="space-between" p={4} key={file.name}>
                <Flex align="center" w="90%">
                  {!!isImage ? (
                    <Image
                      mr={3}
                      rounded="md"
                      width="70px"
                      height="auto"
                      maxH={{ base: '70px', md: 'unset' }}
                      src={window.URL.createObjectURL(file)}
                    />
                  ) : (
                    <File color={theme.colors.brand[300]} />
                  )}
                  <Text ml={4} isTruncated>
                    {file.name}
                  </Text>
                </Flex>
                {progress && progress.state === 'done' ? (
                  <Check size={20} color={theme.colors.success[300]} />
                ) : (
                  <Flex>
                    <IconButton
                      size="xs"
                      icon={<X />}
                      aria-label="Remove File"
                      onClick={() => setStateFiles(stateFiles.filter((e) => e.name !== file.name))}
                    />
                  </Flex>
                )}
                {renderProgress(file.name)}
              </FileWrapper>
            </>
          )
        })}
      {!isDisabled && (
        <>
          <Flex mt={!!label ? 0 : 2} justifyContent="flex-end" width="100%" flexDirection="row">
            <AddFileButton
              isDisabled={isDisabled}
              htmlFor={name}
              mr={stateFiles && stateFiles.length > 0 ? 4 : 0}
              borderRadius="3xl"
              bg={bg ? bg : 'unset'}
              borderStyle="dashed"
              borderColor={isInvalid ? 'error.500' : 'text'}
            >
              <Text color={isInvalid ? 'error.500' : 'text'} fontWeight="light">
                {stateFiles && stateFiles.length > 0 ? 'Add More' : placeholder}
              </Text>
            </AddFileButton>
            {stateFiles && stateFiles.length > 0 && (
              <Button
                borderRadius="3xl"
                isLoading={uploading}
                flex={1}
                onClick={() => handleUpload()}
              >
                <Text>Upload</Text>
              </Button>
            )}
          </Flex>
          <HiddenInput
            disabled={isDisabled}
            onChange={onChange}
            type="file"
            multiple={isMulti}
            name={name}
            id={name}
            accept={fileAccept}
          />
        </>
      )}
      {/* TODO: Figure out how to make this work with meta.touched condition */}
      {showError && isInvalid ? (
        <Text color="red.500" textAlign="right">
          {meta.error}
        </Text>
      ) : null}
    </Wrapper>
  )
}

FileUploader.defaultProps = {
  placeholder: 'Add Files',
  isMulti: false,
  showError: true,
  fileAccept: 'image/*,.doc,.pdf,.docx'
}

export default FileUploader
