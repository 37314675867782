import { useEffect, useState } from 'react'

const useWindowMaxSize = (): { maxWidth: number; maxHeight: number } => {
  const [maxWidth, setMaxWidth] = useState<number>(0)
  const [maxHeight, setMaxHeight] = useState<number>(0)

  useEffect(() => {
    const handleWindowResize = () => {
      setMaxHeight(window.innerHeight)
      setMaxWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleWindowResize)

    handleWindowResize()

    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  return { maxHeight, maxWidth }
}

export default useWindowMaxSize
