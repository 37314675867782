import { Flex, FormLabel, Text, useCheckboxGroup, UseCheckboxGroupProps } from '@chakra-ui/react'
import * as React from 'react'
import { ConnectedCheckboxCard } from '..'

export type Option = {
  label: string | JSX.Element
  value: string
}

export type ConnectedCheckboxCardGroupProps = UseCheckboxGroupProps & {
  label?: string
  options?: Option[]
}

const ConnectedCheckboxCardGroup: React.FC<ConnectedCheckboxCardGroupProps> = ({
  label,
  options,
  onChange
}) => {
  const { value, getCheckboxProps } = useCheckboxGroup()

  React.useEffect(() => {
    if (onChange) onChange(value)
  }, [value])

  return (
    <Flex flexDir="column" w="100%">
      {label && (
        <Flex>
          <FormLabel fontWeight={700} fontSize="17px" color="text">
            {label}
          </FormLabel>
        </Flex>
      )}
      <Flex position="relative" px={3} overflow="scroll">
        {options && options?.length > 0 ? (
          <>
            {options.map(({ label, value }) => {
              const checkbox = getCheckboxProps({ value })
              return (
                <ConnectedCheckboxCard key={value} {...checkbox}>
                  {label}
                </ConnectedCheckboxCard>
              )
            })}
          </>
        ) : (
          <Flex justify="center" alignItems="center" height="145px">
            <Text fontWeight={500} fontSize="16px" color="text" textAlign="center">
              No Investments found.
            </Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

export default ConnectedCheckboxCardGroup
