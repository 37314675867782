import { PrivateRouteObject, SHARED_ROUTES } from 'navigation/routes'
import { lazy } from 'react'

const Notes = lazy(() => import('containers/RegulatoryBody/Notes'))
const Profile = lazy(() => import('containers/RegulatoryBody/Profile'))
const NoteDetails = lazy(() => import('containers/RegulatoryBody/NoteDetails'))
const Dashboard = lazy(() => import('containers/RegulatoryBody/Dashboard'))
const Notifications = lazy(() => import('containers/RegulatoryBody/Notifications'))

export const REGULATORY_ROUTES: PrivateRouteObject[] = [
  {
    exact: true,
    title: 'My Dashboard',
    path: '/auth/my-dashboard',
    component: Dashboard
  },
  {
    exact: true,
    title: 'My Notes',
    path: '/auth/notes',
    component: Notes
  },
  {
    exact: true,
    title: 'My Profile',
    path: '/auth/profile',
    component: Profile
  },
  {
    exact: true,
    title: 'Notifications',
    path: '/auth/notifications',
    component: Notifications
  },
  {
    exact: true,
    title: 'Note View',
    path: '/auth/notes/:id',
    component: NoteDetails
  },
  ...SHARED_ROUTES
]
