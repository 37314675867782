import { Box, useRadio, UseRadioProps } from '@chakra-ui/react'
import * as React from 'react'

export type ConnectedRadioCardProps = UseRadioProps

const ConnectedRadioCard: React.FC<ConnectedRadioCardProps> = (props) => {
  const { getInputProps, getCheckboxProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Box as="label" pl={3} pr={3}>
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="2px"
        borderColor="white"
        borderRadius="3xl"
        boxShadow="xl"
        bg="white"
        alignItems="center"
        _checked={{
          borderColor: 'brand.500'
        }}
      >
        {props.children}
      </Box>
    </Box>
  )
}

export default ConnectedRadioCard
