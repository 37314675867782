import * as React from 'react'
import { pdfjs } from 'react-pdf'
import Navigation from './navigation'
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`

const App: React.FC = () => {
  return <Navigation />
}

export default App
