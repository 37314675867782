import { IToast } from '@chakra-ui/react'
import { OptionType } from 'utils'
import { Enum_Commercialpapers_Interestfrequency, UploadFile } from '../generated/graphql'

export const APP_NAME = 'JamiiPesa'

export const STRAPI_USER_STORAGE_KEY = 'strapi-user'

export const CLIENT_HOST = process.env.REACT_APP_CLIENT_HOST

// Development
export const IS_PROD = process.env.NODE_ENV === 'production'

export const PASSWORD_REGEX_MESSAGE =
  'Password must contain at least 1 lowercase letter, 1 uppercase letter, 1' +
  ' number and 1 special character'

export const SUCCESS_TOAST: IToast = {
  duration: 6000,
  isClosable: true,
  title: 'Success!',
  status: 'success',
  position: 'top-right'
}

export const ERROR_TOAST: IToast = {
  duration: 6000,
  title: 'Oops!',
  status: 'error',
  isClosable: true,
  position: 'top-right'
}

export const DATE_FORMAT = 'DD/MM/YYYY'

export const PHONE_NUMBER_REGEX = new RegExp('^([0]{1})?([1-9]{1}[0-9]{8})$')

export const COUNTRY_CODE = '+27'

export const MPESA_PHONE_NUMBER_REGEX =
  /^(?:254|\+254|0)?((7|1)(?:(?:[12][0-9])|(?:0[0-8])|(9[0-2]))[0-9]{6})$/

export const EMPTY_FILE: UploadFile = {
  id: '',
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
  name: '',
  hash: '',
  mime: '',
  size: 0,
  url: '',
  provider: ''
}

export const WARNING_TOAST: IToast = {
  duration: 6000,
  title: 'Oops!',
  status: 'warning',
  isClosable: true,
  position: 'top-right'
}

export const PAYOUT_OPTIONS_MONTHS: OptionType[] = [
  { label: '0 Months', value: 0 },
  { label: '1 Month', value: 1 },
  { label: '2 Months', value: 2 },
  { label: '3 Months', value: 3 },
  { label: '4 Months', value: 4 },
  { label: '5 Months', value: 5 },
  { label: '6 Months', value: 6 },
  { label: '7 Months', value: 7 },
  { label: '8 Months', value: 8 },
  { label: '9 Months', value: 9 },
  { label: '10 Months', value: 10 },
  { label: '11 Months', value: 11 },
  { label: '12 Months', value: 12 }
]
export const PAYOUT_OPTIONS_YEARS: OptionType[] = [
  { label: '0 Years', value: 0 },
  { label: '1 Year', value: 12 },
  { label: '2 Years', value: 24 },
  { label: '3 Years', value: 36 },
  { label: '4 Years', value: 48 },
  { label: '5 Years', value: 60 },
  { label: '6 Years', value: 72 },
  { label: '7 Years', value: 84 },
  { label: '8 Years', value: 96 },
  { label: '9 Years', value: 108 },
  { label: '10 Years', value: 120 }
]

export const INTEREST_FREQUENCY_OPTIONS: OptionType[] = [
  { label: 'Monthly', value: Enum_Commercialpapers_Interestfrequency.Monthly },
  { label: 'Quarterly', value: Enum_Commercialpapers_Interestfrequency.Quarterly },
  { label: 'Semi-Annually', value: Enum_Commercialpapers_Interestfrequency.SemiAnnually },
  { label: 'Annually', value: Enum_Commercialpapers_Interestfrequency.Annually }
]
