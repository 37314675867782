import * as React from 'react'

type WavesProps = {
  height?: string | number
  width?: string | number
  style?: { bottom?: number; left?: number; right?: number }
}

const Waves: React.FC<WavesProps> = ({ height, width, style }) => (
  <svg
    width={width}
    height={height}
    style={{ position: 'absolute', ...style }}
    viewBox="0 0 1440 402"
    preserveAspectRatio="none"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient
        id="paint0_linear_616:153098"
        x1="546.463"
        y1="225.478"
        x2="543.295"
        y2="449.919"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.104167" stopColor="#6182D5" stopOpacity="0.46" />
        <stop offset="0.921875" stopColor="#395497" stopOpacity="0.21" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_616:153098"
        x1="766.284"
        y1="216.802"
        x2="763.061"
        y2="441.475"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.104167" stopColor="#6182D5" stopOpacity="0.46" />
        <stop offset="0.921875" stopColor="#395497" stopOpacity="0.21" />
      </linearGradient>
    </defs>
    <path
      d="M1442 451.869V157.095C1369.22 123.486 1179.16 68.8727 1001.18 119.285C778.716 182.301 701.642 259.901 505.266 258.154C296.907 256.3 276.986 32.2417 -18.9146 92.1899L-37 451.869H1442Z"
      fill="url(#paint0_linear_616:153098)"
    />
    <path
      d="M1442 451.869V157.095C1369.22 123.486 1179.16 68.8727 1001.18 119.285C778.716 182.301 701.642 259.901 505.266 258.154C296.907 256.3 276.986 32.2417 -18.9146 92.1899L-37 451.869H1442Z"
      stroke="#4B69B2"
      strokeWidth="2"
    />
    <path
      d="M1442 451.869V157.095C1369.22 123.486 1179.16 68.8727 1001.18 119.285C778.716 182.301 701.642 259.901 505.266 258.154C296.907 256.3 276.986 32.2417 -18.9146 92.1899L-37 451.869H1442Z"
      stroke="#4B69B2"
      strokeOpacity="0.2"
      strokeWidth="2"
    />
    <g opacity="0.6">
      <path
        d="M1648.5 443.428V148.348C1576.8 114.705 1389.57 60.0342 1214.24 110.499C995.082 173.581 919.155 251.261 725.7 249.512C520.441 247.656 247.499 -51.045 -44 8.96547V430.086L1648.5 443.428Z"
        fill="url(#paint1_linear_616:153098)"
      />
      <path
        d="M1648.5 443.428V148.348C1576.8 114.705 1389.57 60.0342 1214.24 110.499C995.082 173.581 919.155 251.261 725.7 249.512C520.441 247.656 247.499 -51.045 -44 8.96547V430.086L1648.5 443.428Z"
        stroke="#4B69B2"
        strokeWidth="2"
      />
      <path
        d="M1648.5 443.428V148.348C1576.8 114.705 1389.57 60.0342 1214.24 110.499C995.082 173.581 919.155 251.261 725.7 249.512C520.441 247.656 247.499 -51.045 -44 8.96547V430.086L1648.5 443.428Z"
        stroke="#4B69B2"
        strokeOpacity="0.2"
        strokeWidth="2"
      />
    </g>
  </svg>
)

export default Waves

Waves.defaultProps = {
  height: 202,
  width: '100%',
  style: { bottom: 0, left: 0, right: 0 }
}
