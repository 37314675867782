import { Flex, FlexProps, FormLabel, Select, SelectProps, Text } from '@chakra-ui/react'
import { useField } from 'formik'
import * as React from 'react'
import { LabelProps } from '../styles'

export type OptionType = {
  label: string
  value: any
}

export type ConnectedSelectProps = LabelProps &
  FlexProps &
  SelectProps & {
    label?: string
    name: string
    options: OptionType[]
    showError?: boolean
    onSelectChange?: (txt: string) => void
    dropDownStyle?: SelectProps
    disabled?: boolean
  }

const ConnectedSelect: React.FC<ConnectedSelectProps> = ({
  label,
  options,
  showError,
  onSelectChange,
  dropDownStyle,
  disabled,
  ...rest
}) => {
  const [field, meta] = useField(rest.name)

  React.useEffect(() => {
    onSelectChange && onSelectChange(field.value)
  }, [onSelectChange, field.value])

  const isInvalid = meta.touched && meta.error

  const stateColor = isInvalid ? 'error.100' : dropDownStyle?.bgColor

  return (
    <Flex
      flexDirection="column"
      width={rest.width}
      mr={rest.mr}
      ml={rest.ml}
      mt={rest.mt}
      mb={rest.mb}
    >
      {label && (
        <FormLabel
          fontSize={{ base: 14, md: 'unset' }}
          htmlFor={field.name}
          fontWeight="600"
          color={isInvalid ? 'error.500' : 'text'}
        >
          {label}
        </FormLabel>
      )}
      <Select
        focusBorderColor="accent.500"
        id={field.name}
        {...rest}
        width="100%"
        variant="filled"
        color={field.value ? 'text' : 'muted'}
        {...dropDownStyle}
        bgColor={stateColor}
        borderColor={stateColor}
        {...field}
        disabled={disabled}
        _focus={{ border: 'none', backgroundColor: stateColor }}
      >
        {options.map(({ label, value }) => (
          <option key={value} value={value} autoCapitalize="words">
            {label}
          </option>
        ))}
      </Select>
      {showError && isInvalid ? (
        <Text color="error.500" textAlign="right">
          {meta.error}
        </Text>
      ) : null}
    </Flex>
  )
}

export default React.memo(ConnectedSelect)

ConnectedSelect.defaultProps = {
  mb: 2,
  options: [],
  borderRadius: '50px',
  borderColor: 'white',
  showError: false,
  width: '45%',
  dropDownStyle: {
    bgColor: 'background'
  }
}
