import { Flex, Text } from '@chakra-ui/react'
import * as React from 'react'

export type SecondaryTabItemProps = {
  title: string
  component: React.ReactNode
}

type SecondaryTabViewProps = {
  tabs: SecondaryTabItemProps[]
  selected: number | string
  setSelected: (value: number | string) => void
}

const SecondaryTabView: React.FC<SecondaryTabViewProps> = ({ tabs, selected, setSelected }) => {
  return (
    <Flex
      flex={1}
      width="100%"
      bg="white"
      borderRadius={20}
      m={{ md: 5 }}
      p={{ md: 0 }}
      overflow="auto"
      flexDir={{ base: 'column', md: 'row' }}
    >
      <Flex
        flex={0.17}
        flexDirection={{ md: 'column' }}
        borderRightWidth={{ md: 2 }}
        bg="white"
        borderRightColor="gray.200"
      >
        {tabs.map((tab) => {
          const isSelected = selected === tab.title
          return (
            <Flex
              key={tab.title}
              width={{ base: '50%', md: 'auto' }}
              justifyContent={{ base: 'center', md: 'flex-start' }}
              fontSize={{ base: '14px', md: 'initial' }}
              borderLeftWidth={{ md: 8 }}
              borderBottomWidth={{ base: 4, md: 0 }}
              borderLeftColor={isSelected ? 'primary' : 'white'}
              borderBottomColor={isSelected ? 'primary' : 'white'}
              bg={isSelected ? 'transparentPrimary' : 'white'}
              py={5}
              onClick={() => setSelected(tab.title)}
              _hover={{
                borderLeftColor: 'primary',
                bg: 'transparentPrimary',
                cursor: 'pointer'
              }}
            >
              <Text
                fontWeight={isSelected ? 'bold' : 'normal'}
                color={isSelected ? 'primary' : 'text'}
                ml={{ md: 2 }}
              >
                {tab.title}
              </Text>
            </Flex>
          )
        })}
      </Flex>
      <Flex
        flex={{ md: 0.83 }}
        overflowY="scroll"
        borderRadius={{ base: 20, md: 0 }}
        height="100%"
        bg="white"
      >
        {tabs.find((tab) => tab.title === selected)?.component}
      </Flex>
    </Flex>
  )
}

export default SecondaryTabView
