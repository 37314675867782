import { Flex, FlexProps } from '@chakra-ui/react'
import Waves from 'assets/images/waves'
import * as React from 'react'
import { Helmet } from 'react-helmet'

type PageWrapProps = FlexProps & {
  title: string
  hasWaves?: boolean
}

const PageWrap: React.FC<PageWrapProps> = ({ children, title, hasWaves, ...rest }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Flex flexDirection="row" alignItems="center" {...rest}>
        {children}
        {hasWaves && <Waves />}
      </Flex>
    </>
  )
}

PageWrap.defaultProps = {
  p: 5,
  flex: 1,
  flexDir: 'column',
  align: 'flex-start',
  justify: 'flex-start'
}

export default PageWrap
