import * as React from 'react'

type AppContextType = {
  drawerOpen: boolean
  toggleDrawer: () => void
  closeDrawer: () => void
}

export const AppContext = React.createContext<AppContextType>({
  drawerOpen: false,
  toggleDrawer: () => null,
  closeDrawer: () => null
})

export const useAppContext = (): AppContextType => React.useContext(AppContext)

const AppProvider: React.FC<{}> = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false)

  function toggleDrawer() {
    setDrawerOpen(!drawerOpen)
  }

  function closeDrawer() {
    setDrawerOpen(false)
  }

  return (
    <AppContext.Provider value={{ drawerOpen, toggleDrawer, closeDrawer }}>
      {children}
    </AppContext.Provider>
  )
}

export default AppProvider
