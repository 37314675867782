import { Button, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { BsPencil } from 'react-icons/bs'

type RequestUpdateButtonProps = {
  isDisabled: boolean
  onDisabledChange: (disabled: boolean) => void
  editText?: string
  btnText?: string
  hasUpdated: boolean
  handleSubmit: () => void
}

const RequestUpdateButton: React.FC<RequestUpdateButtonProps> = ({
  isDisabled,
  onDisabledChange,
  editText,
  hasUpdated,
  handleSubmit,
  btnText
}) => {
  return (
    <Flex
      alignSelf={{ md: 'flex-end' }}
      justifyContent={{ base: 'center', md: 'unset' }}
      w={{ base: '100%', md: 'unset' }}
    >
      {isDisabled ? (
        <Button
          alignSelf={{ md: 'flex-end' }}
          borderRadius={20}
          w={{ base: '100%', md: 'unset' }}
          px={10}
          mb={5}
          variant="outline"
          onClick={() => onDisabledChange(false)}
        >
          <BsPencil size={20} color="text" style={{ marginRight: 8 }} /> {btnText}
        </Button>
      ) : (
        <Text
          color="primary"
          textDecoration="underline"
          fontWeight="bold"
          opacity={hasUpdated ? 1 : 0.5}
          mb={5}
          onClick={() => (hasUpdated ? handleSubmit() : onDisabledChange(true))}
          cursor="pointer"
        >
          {editText}
        </Text>
      )}
    </Flex>
  )
}

RequestUpdateButton.defaultProps = {
  editText: 'Submit Update',
  btnText: 'Edit Info'
}

export default RequestUpdateButton
