import { Flex, Heading, Text } from '@chakra-ui/react'
import { ModalWrap } from 'components'
import React from 'react'

type Props = {
  heading: string
  subHeading?: string
  isOpen: boolean
  onClose: () => void
  onOverlayClick: () => void
  bg?: string
  footer: JSX.Element
}

const FormModal: React.FC<Props> = ({
  heading,
  subHeading,
  isOpen,
  onClose,
  onOverlayClick,
  children,
  footer,
  bg
}) => {
  return (
    <ModalWrap
      isOpen={isOpen}
      onClose={onClose}
      onOverlayClick={onOverlayClick}
      bg={bg}
      footer={footer}
    >
      <Flex flexDirection="column" width="100%">
        <Heading fontSize={{ base: '22px', md: '2xl' }} color="text">
          {heading}
        </Heading>
        {subHeading && (
          <Text mt={25} fontSize={14} fontWeight={300}>
            {subHeading}
          </Text>
        )}
        {children}
      </Flex>
    </ModalWrap>
  )
}

export default FormModal

FormModal.defaultProps = {
  bg: 'white'
}
