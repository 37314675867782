import { Input, InputProps } from '@chakra-ui/react'
import { debounce } from 'lodash'
import React, { ChangeEvent, FC, useState } from 'react'

type SearchBarProps = InputProps & {
  onSearch: (text: string) => void
  isLoading?: boolean
  delay?: number
}

const SearchBar: FC<SearchBarProps> = ({ onSearch, delay, ...rest }) => {
  const [searchTerm, setSearchTerm] = useState<string | undefined>()

  const debouncedSearch = React.useRef(
    debounce((nextValue: string) => onSearch(nextValue), delay)
  ).current

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)

    debouncedSearch(e.target.value)
  }

  return (
    <Input
      {...rest}
      variant="filled"
      bg="white"
      color="text"
      borderRadius="50px"
      value={searchTerm}
      onChange={handleChange}
      mb={{ base: 2, md: '0' }}
      w={{ base: '100%' }}
      fontSize={16}
      _focus={{ backgroundColor: 'white', border: 'none' }}
    />
  )
}

export default SearchBar

SearchBar.defaultProps = {
  width: '220px',
  type: 'text',
  placeholder: 'Search...',
  delay: 1500
}
