import styled from '@emotion/styled'
import { theme } from 'theme'

const NotifierBell = styled.span`
  background: ${theme.colors.red[500]};
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
`

export default NotifierBell
