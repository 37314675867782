import { ApolloError } from '@apollo/client'
import { UseToastOptions } from '@chakra-ui/react'
import dayjs from 'dayjs'
import Dinero from 'dinero.js'
import { MasterNote } from 'generated/graphql'
import { capitalize } from 'lodash'
import get from 'lodash/get'
import { ReactNode } from 'react'
import { CountryRegionData } from 'react-country-region-selector'
import { DATE_FORMAT, ERROR_TOAST, STRAPI_USER_STORAGE_KEY, WARNING_TOAST } from '../constants'

Dinero.defaultCurrency = 'KES'

export type OptionType = {
  label: string
  value: any
}

type ErrorStructure = {
  error: string
  message: string
  statusCode: number
}

/**
 * Gets JWT of authenticatedUser from either sessionStorage or localStorage
 */
export const fetchJwt = (): string | null => {
  const localUser = localStorage.getItem(STRAPI_USER_STORAGE_KEY)
  const sessionUser = sessionStorage.getItem(STRAPI_USER_STORAGE_KEY)
  const user = sessionUser || localUser

  return user ? JSON.parse(user).jwt : null
}

export function formatError({ response }: { response: any }): string {
  return get(response, 'data.message[0].messages[0].message', 'An unknown error has occured.')
}

/**
 * function to return a handle errors returned from GraphQL queries/mutations.
 * @param message error message returned and destructured
 * @param toast useToast from Chakra
 * @param description optional *user friendly* message. Recommended
 * not using the graphQL error message.
 * recommended usage:
 * onError: (e) => handleErrors(e, toast, 'fetching your data')
 */
declare function cwr(operation: string, payload: any): void
export const handleErrors = (
  error: ApolloError | Error,
  toast: (props: UseToastOptions) => ReactNode | void,
  description?: string
): ReactNode | void => {
  if (typeof cwr === 'function') {
    cwr('recordError', error)
  }
  if (error.message.includes('403')) {
    return toast({ description: error.message, ...WARNING_TOAST })
  }
  return toast({
    description: description
      ? `Something went wrong with ${description}.`
      : error.message.replace('GraphQL error:', '').trim(),
    ...ERROR_TOAST
  })
}
export const handleAxiosErrors = (
  error: Error,
  toast: (props: UseToastOptions) => ReactNode | void,
  description?: string
): ReactNode | void => {
  let responseError: ErrorStructure = {
    statusCode: 400,
    error: 'Bad Request',
    message: `Something went wrong with ${description}.`
  }

  for (const [key, value] of Object.entries(error)) {
    if (key === 'response') {
      responseError = get(value, 'data', responseError)
    }
  }
  if (typeof cwr === 'function') {
    cwr('recordError', error)
  }
  if (responseError.statusCode === 403) {
    return toast({ description: responseError.message, ...WARNING_TOAST })
  }
  return toast({
    description: description ? `Something went wrong with ${description}.` : responseError.message,
    ...ERROR_TOAST
  })
}

export const enumToOptions = (data: object): OptionType[] => {
  const options: OptionType[] = []
  for (const item of Object.entries(data)) {
    options.push({ value: item[1], label: item[1].replace(/_/g, ' ') })
  }
  return options
}

export const countryOptions = (): OptionType[] => {
  return CountryRegionData.map((el) => ({
    label: el[0],
    value: el[0].replace(/_/g, ' ').toUpperCase()
  }))
}

/**
 * @param amount accepts price in integer format from BE
 * @returns returns price in default currency eg 5000 => KES 50.00
 */
export const formatPrice = (
  amount: number,
  toUnit?: boolean,
  currency?: string
): string | number => {
  return toUnit && amount
    ? amount.toFixed(2)
    : new Intl.NumberFormat('en-US', {
        style: 'currency',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
        currency: currency ? currency : 'KES'
      }).format(amount)
}

export const formatDate = (date: Date): string => dayjs(date).format(DATE_FORMAT)

export const formatEnum = (text: string): string => {
  const newString = text.replace(/_/g, ' ')
  const Nstring = `${newString}`
  return capitalize(Nstring)
}

export const monthsToNextPayout = (
  frequency: number,
  proposedDateOfIssue: string,
  lastPayInterestDate: string
): number => {
  const currentMonth = dayjs()
  const proposedIssueDate = dayjs(proposedDateOfIssue)
  const lastInterestDate = dayjs(lastPayInterestDate)

  const monthsToDateOfIssue = proposedIssueDate.diff(currentMonth, 'month')
  const monthsRemaining =
    monthsToDateOfIssue <= 0
      ? frequency
      : frequency +
        (proposedIssueDate.isAfter(currentMonth)
          ? monthsToDateOfIssue
          : (-1 * monthsToDateOfIssue) % frequency)

  return currentMonth.diff(lastInterestDate, 'month') === 0 && lastInterestDate
    ? frequency
    : monthsRemaining
}

export const frequency = (interestFrequency: string | null | undefined): number => {
  if (interestFrequency === 'MONTHLY') {
    return 1
  } else if (interestFrequency === 'QUARTERLY') {
    return 3
  } else if (interestFrequency === 'SEMI_ANNUALLY') {
    return 6
  } else {
    return 12
  }
}

export const createMasterNoteOptions = (
  masterNotes: MasterNote[] | undefined | null
): OptionType[] => {
  const options: OptionType[] = []
  if (masterNotes) {
    for (const note of masterNotes) {
      options.push({ label: note.title as string, value: note.id })
    }
  }
  return options
}
