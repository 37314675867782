import { Flex, Text } from '@chakra-ui/react'
import { useAuthContext } from 'context/AuthProvider'
import React from 'react'
import { BiCoin } from 'react-icons/bi'
import { formatPrice } from 'utils'

type InvestedProgressbarProps = {
  investedAmount: number
  investmentNeeded: number
  percentages: {
    myContributionPercentage: number
    percentageRaised: number
  }
}

const InvestedProgressbar: React.FC<InvestedProgressbarProps> = ({
  investedAmount,
  investmentNeeded,
  percentages
}) => {
  const { walletCurrency } = useAuthContext()
  return (
    <Flex flexDirection="column" my={5}>
      <Text fontSize={{ base: 'md', md: 'lg' }} fontWeight="bold" color="text">
        Amount Purchased Already
      </Text>
      <Flex flexDirection="row" mt={5} width="100%" alignItems="center">
        <Flex
          bg="transparentSecondary"
          borderRadius={10}
          justifyContent="center"
          alignItems="center"
          boxSize={35}
        >
          <BiCoin color="text" size={30} />
        </Flex>
        <Flex flexDirection="column" flex={1} px={3}>
          <Flex alignItems="center">
            <Flex
              width="100%"
              borderRadius="full"
              height="20px"
              bg="background"
              alignItems="flex-start"
              justifyContent="flex-start"
              overflow="hidden"
              position="relative"
            >
              <Flex
                height="100%"
                width={`${percentages.percentageRaised}%`}
                bg="tertiary"
                borderEndRadius="full"
                position="absolute"
                left={0}
                top={0}
                bottom={0}
                zIndex={15}
              />
              <Flex
                height="100%"
                width={`${percentages.myContributionPercentage}%`}
                bg="secondary"
                borderRadius="full"
                position="absolute"
                left={0}
                top={0}
                bottom={0}
                zIndex={10}
              />
            </Flex>
            <Text color="text" fontSize={{ base: '12px', md: '16px' }} ml={2}>
              {percentages.percentageRaised.toFixed(2)}%
            </Text>
          </Flex>
          <Text
            mt={2}
            fontSize={{ base: '12px', md: 'sm' }}
            color="text"
            opacity={0.5}
          >{`${formatPrice(investedAmount, false, walletCurrency)}/${formatPrice(
            investmentNeeded,
            false,
            walletCurrency
          )}`}</Text>
        </Flex>
      </Flex>
      {!!investedAmount && (
        <Flex mt={5}>
          <Flex alignItems="center">
            <Flex height="10px" width="30px" borderRadius={10} bg="secondary" mr={2} />
            <Text fontSize={{ base: '11px', md: '16px' }}>Contributed by others</Text>
          </Flex>
          <Flex alignItems="center" ml={5}>
            <Flex height="10px" width="30px" borderRadius={10} bg="tertiary" mr={2} />
            <Text fontSize={{ base: '11px', md: '16px' }}>Contributed by you</Text>
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}

export default InvestedProgressbar
