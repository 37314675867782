import { Checkbox, CheckboxProps, Flex, Text } from '@chakra-ui/react'
import { useField } from 'formik'
import { capitalize, get } from 'lodash'
import * as React from 'react'
import { OptionType } from 'utils'
import { LabelProps } from '../styles'

export type ConnectedCheckboxArrayProps = LabelProps &
  CheckboxProps & {
    name: string
    labelStyle?: CheckboxProps
    options: OptionType[]
    showError?: boolean
  }

const ConnectedCheckboxArray: React.FC<ConnectedCheckboxArrayProps> = ({
  options,
  labelStyle,
  showError,
  ...rest
}) => {
  // Field has to be an array
  const [field, meta, helpers] = useField(rest.name)

  const onChecked = (checked: boolean, value: string) => {
    const valueArr = get(field, 'value', [])
    const hasValues = valueArr.length ? valueArr.find((el: string) => el === value) : null

    if (!hasValues && checked) {
      helpers.setValue([...valueArr, value])
    } else if (hasValues && !checked) {
      helpers.setValue(((valueArr as []) || []).filter((el) => el !== value))
    }
  }

  const isInvalid = meta.touched && meta.error
  return (
    <Flex
      mb={rest.mb}
      ml={rest.ml}
      mr={rest.mr}
      mt={rest.mt}
      width="100%"
      align="center"
      justify={rest.justifyContent}
    >
      <Flex alignItems="flex-start" flexDirection="column">
        {options.map((el) => (
          <Checkbox
            key={el.value}
            isChecked={((field.value as string[]) || []).includes(el.value)}
            onChange={(e) => onChecked(e.target.checked, el.value)}
            borderColor={isInvalid ? 'error.500' : 'text'}
            fontWeight="black"
            color="text"
            {...labelStyle}
          >
            {capitalize(el.label)}
          </Checkbox>
        ))}
        {isInvalid && showError ? (
          <Text fontWeight="bold" color="error.500" mt={2}>
            {meta.error}
          </Text>
        ) : null}
      </Flex>
    </Flex>
  )
}

export default ConnectedCheckboxArray

ConnectedCheckboxArray.defaultProps = {
  mb: 2,
  alignItems: 'center',
  justifyContent: 'flex-start'
}
