import { animate } from 'framer-motion'
import React, { useEffect, useRef } from 'react'

const CounterRef: React.FC<{ from: number; to: number }> = ({ from, to }) => {
  const nodeRef = useRef() as React.MutableRefObject<HTMLDivElement>

  useEffect(() => {
    const node = nodeRef.current

    if (node) {
      const controls = animate(from, to, {
        duration: 1,
        onUpdate(value) {
          node.textContent = Math.trunc(value).toString()
        }
      })

      return () => controls.stop()
    }
  }, [from, to])

  return <div ref={nodeRef} />
}

const Counter: React.FC<{ from: number; to: number }> = ({ from, to }) => {
  return <CounterRef from={from} to={to} />
}

export default Counter
