import { Box, Flex, FlexProps, Grid, GridItem, Text } from '@chakra-ui/react'
import { get, orderBy } from 'lodash'
import React, { ReactElement } from 'react'
import { BsChevronDown, BsChevronLeft, BsChevronRight, BsChevronUp } from 'react-icons/bs'
import { TiArrowUnsorted } from 'react-icons/ti'
import Skeleton from 'react-loading-skeleton'
import CardWrapper from '../../containers/Dashboard/components/CardWrapper'

export type ColumnType = {
  Header: string
  accessor?: string
  formatter?: (value: string | number) => string | ReactElement | number
}

type TableProps = {
  pages: number
  marketValueLog?: boolean
  columns: ColumnType[]
  data: any[]
  isLoading: boolean
  page: number
  onPageChange: (page: number) => void
  onRowClicked?: (id: string) => void
  withoutPagination?: boolean
  defaultIteratee?: string
  defaultOrder?: 'asc' | 'desc'
}

type GeneratePagesProps = {
  pages: number
  onPageChange: (index: number) => void
  isLoading: boolean
  page: number
  containerStyle?: FlexProps
}

export const GeneratePages: React.FC<GeneratePagesProps> = ({
  pages,
  onPageChange,
  isLoading,
  page,
  containerStyle
}) => {
  const pageArr = Array(pages).fill(1)

  const onNext = () => {
    if (page < pages && !isLoading) {
      onPageChange(page + 1)
    }
  }

  const onPrevious = () => {
    if (page !== 1 && !isLoading) {
      onPageChange(page - 1)
    }
  }

  return (
    <Flex justifyContent="flex-end" mt={5} mr={5} alignItems="center" {...containerStyle}>
      <Box
        p={2}
        bg={page !== 1 ? 'white' : 'transparent'}
        borderRadius={10}
        shadow={page !== 1 ? 'xl' : 'none'}
        cursor={page !== 1 ? 'pointer' : 'not-allowed'}
        onClick={onPrevious}
      >
        <BsChevronLeft />
      </Box>
      {pageArr.map((el, index) => {
        const isCurrent = index + 1 === page
        return (
          <Flex
            key={`IndexButton-${index}`}
            height={8}
            width={8}
            bg={isCurrent ? 'white' : 'transparent'}
            borderRadius={10}
            shadow={isCurrent ? 'xl' : 'none'}
            cursor={'pointer'}
            justifyContent="center"
            alignItems="center"
            onClick={() => {
              if (!isCurrent && !isLoading) {
                onPageChange(index + 1)
              }
            }}
          >
            <Text textAlign="center">{index + 1}</Text>
          </Flex>
        )
      })}
      <Box
        p={2}
        bg={page !== pages ? 'white' : 'transparent'}
        borderRadius={10}
        shadow={page !== pages ? 'xl' : 'none'}
        cursor={page !== pages ? 'pointer' : 'not-allowed'}
        onClick={onNext}
      >
        <BsChevronRight />
      </Box>
    </Flex>
  )
}

const Table: React.FC<TableProps> = ({
  columns,
  pages,
  marketValueLog,
  data,
  isLoading,
  page,
  onPageChange,
  onRowClicked,
  withoutPagination,
  defaultIteratee,
  defaultOrder
}) => {
  const [selected, setSelected] = React.useState<null | number>()
  const [order, setOrder] = React.useState<'asc' | 'desc'>(defaultOrder || 'asc')
  const [iteratee, setIteratee] = React.useState(defaultIteratee)
  const [ViewMore, setViewMore] = React.useState<ReactElement | null>(null)

  React.useEffect(() => {
    setIteratee(defaultIteratee)
    setOrder(defaultOrder || 'asc')
  }, [defaultIteratee, defaultOrder])

  React.useEffect(() => {
    setSelected(null)
    setViewMore(null)
  }, [page, order])

  const renderRows = () => (
    <Flex flexDirection="column" width="100%">
      {orderBy(data, [iteratee], [order]).map((el, key) => {
        const isSelected = selected === key

        return (
          <Flex flexDirection="column" width="100%" key={key}>
            <Grid width="100%" templateColumns={`repeat(${columns.length}, 1fr)`}>
              {columns.map(({ accessor, formatter }, columnIndex) => {
                const value = get(el, `[${accessor}]`)

                const ViewMoreSection = get(el, 'ViewMoreSection')

                const bg = columnIndex % 2 !== 0 || !accessor ? 'white' : 'rgba(75, 105, 178, 0.2)'

                return (
                  <GridItem key={key + 1}>
                    <Flex
                      borderBottomWidth={1}
                      bg={isSelected ? 'transparentPrimary' : bg}
                      justifyContent="center"
                      alignItems="center"
                      py={3}
                      height="60px"
                      fontWeight={isSelected ? 'bold' : 'normal'}
                      borderRightWidth={accessor ? 1 : 0}
                      onClick={() =>
                        accessor && onRowClicked ? onRowClicked(get(el, 'id')) : null
                      }
                    >
                      {accessor ? (
                        <Text textAlign="center">{formatter ? formatter(value) : value}</Text>
                      ) : (
                        <Flex
                          flexDirection="row"
                          alignItems="center"
                          cursor="pointer"
                          onClick={() => {
                            if (isSelected) {
                              setSelected(null)
                              setViewMore(null)
                            } else {
                              setSelected(key)
                              setViewMore(ViewMoreSection || null)
                            }
                          }}
                        >
                          <Text textAlign="center" mr={5}>
                            View
                          </Text>
                          {isSelected ? <BsChevronUp /> : <BsChevronDown />}
                        </Flex>
                      )}
                    </Flex>
                  </GridItem>
                )
              })}
            </Grid>
            {ViewMore && isSelected ? (
              <Flex width="100%" p={5} bg="white">
                {ViewMore}
              </Flex>
            ) : null}
          </Flex>
        )
      })}
    </Flex>
  )

  if (isLoading) {
    return (
      <Skeleton
        baseColor="#c2c2c2"
        count={1}
        height="300px"
        width="100%"
        style={{ borderRadius: '24px' }}
      />
    )
  }
  if (!isLoading && data.length === 0) {
    return (
      <>
        {marketValueLog ? (
          <CardWrapper width="100%" height="400px">
            <Text color="gray.900" fontWeight={600} fontSize={24} mb={4}>
              No Market Value Log
            </Text>
          </CardWrapper>
        ) : (
          <CardWrapper width="100%" height="400px">
            <Text color="gray.900" fontWeight={600} fontSize={24} mb={4}>
              Notes not found!
            </Text>
          </CardWrapper>
        )}
      </>
    )
  }

  return (
    <Flex flexDirection="column">
      {/* Render Headers */}
      <Grid
        width="100%"
        templateColumns={`repeat(${columns.length}, 1fr)`}
        borderTopRadius={20}
        overflow="hidden"
      >
        {columns.map(({ Header, accessor }) => {
          return (
            <GridItem key={accessor} width="100%">
              <Flex flexDirection="column">
                <Flex bg="secondary" flex={1} justifyContent="center" alignItems="center" py={5}>
                  <Text fontSize="md" color="background" fontWeight="bold">
                    {Header}
                  </Text>
                  {accessor && (
                    <Flex
                      ml={3}
                      onClick={() => {
                        setIteratee(accessor || 'id')
                        setOrder((currentOrder) => (currentOrder === 'asc' ? 'desc' : 'asc'))
                      }}
                    >
                      <TiArrowUnsorted color="white" />
                    </Flex>
                  )}
                </Flex>
              </Flex>
            </GridItem>
          )
        })}
      </Grid>
      <Flex borderBottomRadius={20} overflow="hidden" width="100%">
        {renderRows()}
      </Flex>
      {!withoutPagination && (
        <GeneratePages
          page={page}
          pages={pages}
          onPageChange={onPageChange}
          isLoading={isLoading}
        />
      )}
    </Flex>
  )
}

export default Table

Table.defaultProps = {
  defaultIteratee: 'id',
  defaultOrder: 'asc'
}
