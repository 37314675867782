import { Flex, InputProps, useMediaQuery } from '@chakra-ui/react'
import { useField } from 'formik'
import moment from 'moment'
import React from 'react'
import { DateRangePicker, FocusedInputShape, isInclusivelyBeforeDay } from 'react-dates'
import { LabelProps } from '../styles'
import { DateRangePickerWrapper } from './styles'

type ConnectedDatePickerProps = LabelProps &
  InputProps & {
    name: string
    onDatesChange: (arg: { startDate: moment.Moment | null; endDate: moment.Moment | null }) => void
    startDatePlaceholderText?: string
    endDatePlaceholderText?: string
    width?: string | number
    isBeforeDay?: boolean
  }

const ConnectedDateRangePicker: React.FC<ConnectedDatePickerProps> = ({
  onDatesChange,
  startDatePlaceholderText,
  endDatePlaceholderText,
  width,
  isBeforeDay,
  ...rest
}) => {
  const [isMobileSize] = useMediaQuery('(max-width: 768px)')

  const [field] = useField(rest.name)
  const [focusedInput, setFocusedInput] = React.useState<FocusedInputShape | null>(null)
  const [startDate, setStartDate] = React.useState<moment.Moment | null>(null)
  const [endDate, setEndDate] = React.useState<moment.Moment | null>(null)

  return (
    <Flex width={{ base: '100%', md: width }} {...rest}>
      <DateRangePickerWrapper>
        <DateRangePicker
          verticalHeight={40}
          numberOfMonths={isMobileSize ? 1 : 2}
          startDatePlaceholderText={startDatePlaceholderText}
          endDatePlaceholderText={endDatePlaceholderText}
          startDateId={`${field.name}_unique_start_date_id`}
          endDateId={`${field.name}_unique_end_date_id`}
          onDatesChange={({ startDate, endDate }) => {
            setStartDate(startDate)
            setEndDate(endDate)
            onDatesChange({ startDate, endDate })
          }}
          onFocusChange={(focusedInput) => {
            setFocusedInput(focusedInput)
          }}
          focusedInput={focusedInput}
          startDate={startDate}
          endDate={endDate}
          {...(isBeforeDay && { isOutsideRange: (day) => !isInclusivelyBeforeDay(day, moment()) })}
          showClearDates
        />
      </DateRangePickerWrapper>
    </Flex>
  )
}
export default ConnectedDateRangePicker
