import { Flex, FlexProps, Spinner, Text } from '@chakra-ui/react'
import styled from '@emotion/styled'
import React from 'react'

const Wrapper = styled(Flex)<FlexProps>`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  background-size: cover;
  justify-content: center;
  width: 100%;
  flex-direction: column;
`

type LoaderProps = {
  color?: string
  description?: string
  height?: string
  position?: 'relative' | 'absolute'
  spinnerRadius?: number
}

const Loader: React.FC<LoaderProps> = ({
  color,
  description,
  position,
  height,
  spinnerRadius = 30
}) => {
  return (
    <Wrapper height={height || '100vh'} position={position || 'absolute'}>
      <Spinner
        color={color || 'brand.500'}
        size="lg"
        thickness="3px"
        speed="0.65s"
        emptyColor="gray.200"
        height={`${spinnerRadius}px`}
        width={`${spinnerRadius}px`}
      />
      {description && (
        <Text mt={2} ml="4px" alignItems="center">
          {description}
        </Text>
      )}
    </Wrapper>
  )
}

export default Loader
