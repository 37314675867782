import {
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps
} from '@chakra-ui/react'
import * as React from 'react'
import { images } from 'theme'

type ModalWrapProps = ModalProps & { heading?: string; bg?: string; footer?: JSX.Element }

const ModalWrap: React.FC<ModalWrapProps> = ({
  children,
  onClose,
  isOpen,
  heading,
  footer,
  bg,
  ...rest
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} {...rest}>
      <ModalOverlay />
      <ModalContent w={{ base: 'auto' }} shadow="none" bg={bg} overflow="hidden" borderRadius="3xl">
        {heading && (
          <ModalHeader>
            <Heading fontSize={{ base: '22px', md: '3xl' }} color="text">
              {heading}
            </Heading>
          </ModalHeader>
        )}
        <ModalCloseButton />
        <ModalBody>
          <Flex flex={1} zIndex={10} p={3} width="auto" height="auto">
            {children}
          </Flex>
        </ModalBody>
        <ModalFooter padding={0}>
          <Flex
            bgImage={{ md: `url(${images.modalWave})` }}
            bgRepeat="no-repeat"
            bgPosition={{ base: 'bottom', md: 'center' }}
            bgSize="auto"
            minHeight={{ base: '5rem', md: '10rem' }}
            width="100%"
            direction="column"
            justifyContent="flex-end"
          >
            {footer}
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ModalWrap

ModalWrap.defaultProps = {
  size: 'xl',
  scrollBehavior: 'inside',
  isCentered: true,
  preserveScrollBarGap: true,
  bg: 'white'
}
