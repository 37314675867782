import styled from '@emotion/styled'
import React from 'react'
import { Variants } from 'framer-motion'

import { MotionFlex } from '..'
import { Counter } from 'components'

const ProgressWrapper = styled(MotionFlex)`
  background: #3eb489;
  height: 100%;
  width: 0%;
`

const CounterWrapper = styled(MotionFlex)`
  width: 30px;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
`

const ProgressContainerWrapper = styled(MotionFlex)`
  background: #eee;
  height: 46px;
  border-radius: 6px;
  overflow: hidden;
`

const containerVariants: Variants = {
  initial: {
    x: -100
  },
  animate: {
    x: 0,
    transition: {
      staggerChildren: 1.5,
      when: 'beforeChildren'
    }
  }
}

const progressContainerVariants: Variants = {
  initial: {
    y: 20,
    opacity: 0
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1
    }
  }
}

const progressVariants = (percent: number): Variants => {
  return {
    initial: {
      width: 0
    },
    animate: {
      width: `${percent}%`,
      transition: {
        duration: 1.5
      }
    }
  }
}

type ProgressBarProps = { percent: number; widthInPX: string }

const ProgressBar: React.FC<ProgressBarProps> = ({ percent, widthInPX }) => {
  return (
    <MotionFlex padding={2} variants={containerVariants} initial="initial" animate="animate">
      <ProgressContainerWrapper variants={progressContainerVariants} width={widthInPX}>
        <ProgressWrapper variants={progressVariants(percent)}></ProgressWrapper>
      </ProgressContainerWrapper>
      <CounterWrapper>
        <Counter from={0} to={percent} />%
      </CounterWrapper>
    </MotionFlex>
  )
}

export default ProgressBar
