import { useToast } from '@chakra-ui/react'
import { socket } from 'context/NotificationsProvider'
import { useTopupMyWalletMutation } from 'generated/graphql'
import * as React from 'react'
import { handleErrors } from 'utils'
import { ERROR_TOAST, SUCCESS_TOAST } from '../../constants'

type TransactionsContextType = {
  walletBalance: number
  topupWallet: (amount: string, phoneNumber: string, source: string) => Promise<void>
  setBalance: (balance: number) => void
  purchaseNote: (amount: number) => void
  loadingTopup: boolean
  pendingTopup: boolean
}

export const TransactionsContext = React.createContext<Partial<TransactionsContextType>>({})

export const useTransactionsContext = (): Partial<TransactionsContextType> =>
  React.useContext(TransactionsContext)

export const TransactionsProvider: React.FC = ({ children }) => {
  const toast = useToast()
  const [pendingTopup, setPendingTopup] = React.useState<boolean>(false)

  const [walletBalance, setWalletBalance] = React.useState(0)

  const [topupMyWallet, { loading: loadingTopup }] = useTopupMyWalletMutation({
    onError: (event) => {
      setPendingTopup(false)
      handleErrors(event, toast)
    },
    onCompleted: () => {
      toast({
        ...SUCCESS_TOAST,
        description:
          'Transaction started successfully. Enter PIN on your phone to authorize transaction.'
      })
    }
  })

  const handleTopup = async (amount: string, phoneNumber: string, source: string) => {
    setPendingTopup(true)
    let formattedPhoneNumber
    if (phoneNumber.substring(0, 3) !== '254') {
      formattedPhoneNumber = `254${phoneNumber}`
    } else {
      formattedPhoneNumber = phoneNumber
    }
    await topupMyWallet({
      variables: {
        input: {
          amount: amount,
          phoneNumber: formattedPhoneNumber,
          source
        }
      }
    })
  }

  const setBalance = (balance: number) => {
    setWalletBalance(balance)
  }

  // Listen to wallet balance updates
  socket.on('successful-topup', (arg) => {
    setPendingTopup(false)
    toast({
      ...SUCCESS_TOAST,
      description: arg
    })
  })

  // Listen to unsuccessful topup
  socket.on('unsuccessful-topup', (arg) => {
    setPendingTopup(false)
    toast({
      ...ERROR_TOAST,
      description: arg
    })
  })

  return (
    <TransactionsContext.Provider
      value={{
        loadingTopup,
        setBalance,
        pendingTopup,
        walletBalance,
        topupWallet: handleTopup,
        purchaseNote: (amount: number) => alert(`Amount ${amount} is invested`)
      }}
    >
      {children}
    </TransactionsContext.Provider>
  )
}

export default TransactionsProvider
