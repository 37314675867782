import { Checkbox, CheckboxProps, Flex } from '@chakra-ui/react'
import { useField } from 'formik'
import * as React from 'react'
import { LabelProps } from '../styles'

export type ConnectedCheckboxProps = LabelProps &
  CheckboxProps & {
    label?: string
    name: string
    labelStyle?: CheckboxProps
    checkBoxType?: 'Ts&Cs' | 'Text'
    onCheckChange?: (checked: boolean) => void
  }

const ConnectedCheckbox: React.FC<ConnectedCheckboxProps> = ({
  label,
  labelStyle,
  checkBoxType,
  children,
  onCheckChange,
  ...rest
}) => {
  const [field, meta] = useField(rest.name)

  React.useEffect(() => {
    if (onCheckChange) {
      onCheckChange(field.value)
    }
  }, [field.value])

  const isInvalid = meta.touched && meta.error
  return (
    <Flex
      mb={rest.mb}
      ml={rest.ml}
      mr={rest.mr}
      mt={rest.mt}
      width="100%"
      align="center"
      justify={rest.justifyContent}
    >
      {checkBoxType === 'Text' ? (
        <Checkbox
          {...field}
          size="lg"
          id={field.name}
          borderColor={isInvalid ? 'error.500' : 'text'}
          {...labelStyle}
        >
          {label}
        </Checkbox>
      ) : (
        <Flex alignItems="center">
          <Checkbox
            {...field}
            size="lg"
            id={field.name}
            borderColor={isInvalid ? 'error.500' : 'text'}
          />
          {children}
        </Flex>
      )}
    </Flex>
  )
}

export default ConnectedCheckbox

ConnectedCheckbox.defaultProps = {
  mb: 2,
  alignItems: 'center',
  justifyContent: 'flex-start',
  checkBoxType: 'Text'
}
