import { Box, Text } from '@chakra-ui/react'
import { Enum_Commercialpapers_Status } from 'generated/graphql'
import React from 'react'

type RenderStatusProps = {
  status: Enum_Commercialpapers_Status
}

const RenderStatus: React.FC<RenderStatusProps> = ({ status }) => {
  const getColors = (): { color: string; bg: string; borderColor: string } => {
    switch (status) {
      case Enum_Commercialpapers_Status.Approved:
        return { color: 'primary', bg: 'transparentPrimary', borderColor: 'primary' }
      case Enum_Commercialpapers_Status.Draft:
        return { color: '#79797A', bg: 'rgba(121, 121, 122, 0.2)', borderColor: '#79797A' }
      case Enum_Commercialpapers_Status.Declined:
        return { color: '#1C1E20', bg: 'rgba(28, 30, 32, 0.2)', borderColor: '#1C1E20' }
      case Enum_Commercialpapers_Status.Submitted:
        return { color: '#1F8073', bg: 'rgba(31, 128, 115, 0.2)', borderColor: '#1F8073' }
      case Enum_Commercialpapers_Status.Funding:
        return { color: '#9C428C', bg: 'rgba(156, 66, 140, 0.2)', borderColor: '#9C428C' }
      case Enum_Commercialpapers_Status.InfoRequired:
        return { color: '#DD8243', bg: 'rgba(221, 130, 67, 0.2)', borderColor: '#DD8243' }
      case Enum_Commercialpapers_Status.PaymentDue:
        return { color: '#DD5F43', bg: 'rgba(221, 95, 67, 0.2)', borderColor: '#DD5F43' }
      case Enum_Commercialpapers_Status.Active:
        return { color: '#2555B0', bg: 'rgba(37, 85, 176, 0.2)', borderColor: '#2555B0' }
      default:
        return { color: 'secondary', bg: 'transparentSecondary', borderColor: 'secondary' }
    }
  }

  return (
    <Box
      borderRadius={20}
      px={2}
      py={1}
      bg={getColors().bg}
      borderColor={getColors().borderColor}
      borderWidth={1}
    >
      <Text color={getColors().color} fontWeight="bold" fontSize="xs">
        {status.replace(/_/g, ' ')}
      </Text>
    </Box>
  )
}

export default RenderStatus
