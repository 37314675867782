import { NavItem } from 'navigation'
import React from 'react'
import { BsFillBarChartFill } from 'react-icons/bs'

export const MANAGER_NAV_ITEMS: NavItem[] = [
  {
    to: '/auth/my-dashboard',
    icon: <BsFillBarChartFill size={20} />,
    title: 'My Dashboard'
  }
]
