import { Flex, Icon, Text, useMediaQuery } from '@chakra-ui/react'
import { useAppContext } from 'context/AppProvider'
import { RoleType, useAuthContext } from 'context/AuthProvider'
import get from 'lodash/get'
import { getNavItems } from 'navigation'
import * as React from 'react'
import { RiMenuLine } from 'react-icons/ri'
import useBreadcrumbs from 'use-react-router-breadcrumbs'

const Breadcrumbs = (): JSX.Element => {
  const { userRole } = useAuthContext()
  const [isMobileSize] = useMediaQuery('(max-width: 768px)')
  const { toggleDrawer } = useAppContext()
  const breadcrumbs = useBreadcrumbs(getNavItems(userRole as RoleType).routes)
  const slicedBreadcrumbs = isMobileSize ? breadcrumbs.slice(-1) : breadcrumbs.slice(2)

  return (
    <Flex flexDirection="row" alignItems="center">
      {slicedBreadcrumbs.map(({ breadcrumb, match }, index) => {
        const navItem = getNavItems(userRole as RoleType).navItems.find((el) =>
          el.to.includes(match.url)
        )

        const NavIcon = get(navItem, 'icon')
        const NavTitle = get(navItem, 'title')

        return (
          <React.Fragment key={match.url}>
            <Flex
              mx={{ base: '1rem', md: '0px' }}
              borderRadius={{ base: '1.5rem' }}
              mt={6}
              display={{ base: 'flex', md: 'none' }}
              p={3}
              mb={{ md: '10px' }}
              backgroundColor={{ base: '#FFF', md: 'transparent' }}
              w={{ base: '100%', md: 'auto' }}
            >
              <Flex ml={{ base: 2, md: 0 }}>
                <Icon
                  onClick={toggleDrawer}
                  alignSelf="center"
                  as={RiMenuLine}
                  height="26px"
                  display={{ md: 'none' }}
                  width="26px"
                  mr={6}
                />
                <Flex flexDirection="row" alignItems="center" color="primary">
                  {NavIcon}
                  <Text
                    color="text"
                    textTransform="capitalize"
                    fontWeight="bold"
                    ml={NavIcon ? 3 : 0}
                    fontSize="xl"
                  >
                    {NavTitle || breadcrumb}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex
              mt={6}
              flexDirection="row"
              alignItems="center"
              color="primary"
              display={{ base: 'none', md: 'flex' }}
            >
              {NavIcon}
              <Text
                color="text"
                textTransform="capitalize"
                fontWeight="bold"
                ml={NavIcon ? 3 : 0}
                fontSize="xl"
              >
                {NavTitle || breadcrumb}
              </Text>
              {index < slicedBreadcrumbs.length - 1 && (
                <Text
                  color="text"
                  textTransform="capitalize"
                  fontWeight="bold"
                  mx={2}
                  fontSize="xl"
                >
                  {` > `}
                </Text>
              )}
            </Flex>
          </React.Fragment>
        )
      })}
    </Flex>
  )
}

export default Breadcrumbs
