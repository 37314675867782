import { Flex } from '@chakra-ui/react'
import React from 'react'

type CardWrapperProps = {
  padding?: number
  width?: number | string
  height?: number | string
}

const CardWrapper: React.FC<CardWrapperProps> = ({ children, padding, width, height }) => (
  <Flex
    borderRadius="3xl"
    shadow="xl"
    bg="white"
    borderColor="white"
    height={height}
    width={width}
    padding={padding}
    justify="center"
    direction="column"
    alignItems="center"
  >
    {children}
  </Flex>
)

export default CardWrapper
