import { get } from 'lodash'
import * as React from 'react'
import io from 'socket.io-client'

import {
  Notifications,
  useGetMyNotificationsLazyQuery,
  useArchiveNotificationMutation
} from '../../generated/graphql'

type NotificationsContextType = {
  notifications: Notifications[]
  handleArchiveNotification: (id: string) => Promise<void>
}

/**
 * create socket.io client instance
 */
export const socket = io(process.env.REACT_APP_API_HOST as string)

export const NotificationsContext = React.createContext<Partial<NotificationsContextType>>({})

export const useNotificationsContext = (): Partial<NotificationsContextType> =>
  React.useContext(NotificationsContext)

const NotificationsProvider: React.FC = ({ children }) => {
  const [getMyNotifications, { data: userNotifications, refetch }] =
    useGetMyNotificationsLazyQuery()
  const fetchedNotifications = get(userNotifications, 'getMyNotifications') as Notifications[]

  React.useEffect(() => {
    getMyNotifications()
  }, [fetchedNotifications])

  const [archiveNotification] = useArchiveNotificationMutation()

  const handleArchiveNotification = async (id: string) => {
    await archiveNotification({
      variables: {
        id
      }
    })
    if (refetch) {
      refetch()
    }
  }

  // Listen to new events
  socket.on('new-notification', () => refetch && refetch())

  return (
    <NotificationsContext.Provider
      value={{ notifications: fetchedNotifications, handleArchiveNotification }}
    >
      {children}
    </NotificationsContext.Provider>
  )
}

export default NotificationsProvider
