import { Box, Flex, FlexProps, Icon, Text, Tooltip } from '@chakra-ui/react'
import React from 'react'
import { HiInformationCircle } from 'react-icons/hi'

type ReadOnlyFieldProps = {
  label: string
  value?: string | number
  tip?: string
} & FlexProps

const ReadOnlyField: React.FC<ReadOnlyFieldProps> = ({ label, value, tip, ...rest }) => (
  <Flex direction="column" width={rest.width || '100%'} mb={{ md: 6 }} {...rest}>
    <Text fontSize={14} fontWeight={{ md: 600 }} mb={1}>
      {label}
      {tip && (
        <Tooltip
          hasArrow
          placement="top"
          bg="secondary"
          color="background"
          label={tip}
          borderRadius="xl"
          alignItems="center"
        >
          <span>
            <Icon as={HiInformationCircle} color="secondary"></Icon>
          </span>
        </Tooltip>
      )}
    </Text>

    <Box
      bgColor="gray.100"
      borderRadius={32}
      width="100%"
      minHeight="44px"
      padding="12px"
      alignItems="center"
    >
      <Text fontWeight={300} fontSize={14}>
        {value || 'none'}
      </Text>
    </Box>
  </Flex>
)

export default ReadOnlyField
