import { PrivateRouteObject } from 'navigation/routes'
import { lazy } from 'react'

const Dashboard = lazy(() => import('containers/Manager/Dashboard'))

export const MANAGER_ROUTES: PrivateRouteObject[] = [
  {
    exact: true,
    title: 'My Dashboard',
    path: '/auth/my-dashboard',
    component: Dashboard
  }
]
