import { Button, Flex, Image, Text } from '@chakra-ui/react'
import { ModalWrap } from 'components'
import React from 'react'
import { images } from 'theme'

type Action = {
  label: string
  onClick: () => void
}
type Props = {
  heading: string
  content: string | JSX.Element
  isOpen: boolean
  onClose: () => void
  onOverlayClick: () => void
  primaryAction?: Action
  secondaryAction?: Action
}

const ConfirmationModal: React.FC<Props> = ({
  heading,
  content,
  isOpen,
  onClose,
  onOverlayClick,
  primaryAction,
  secondaryAction
}) => {
  return (
    <ModalWrap
      isOpen={isOpen}
      onClose={onClose}
      onOverlayClick={onOverlayClick}
      footer={
        <Flex width="100%" justify="center" align="center" mt={{ md: 10 }}>
          {secondaryAction && (
            <Button
              color="white"
              borderRadius="3xl"
              colorScheme="whiteAlpha"
              variant="outline"
              onClick={secondaryAction.onClick}
              mr={6}
              width={152}
            >
              {secondaryAction.label}
            </Button>
          )}
          {primaryAction && (
            <Button
              width={152}
              mb={5}
              colorScheme="brand"
              color="white"
              borderRadius="3xl"
              onClick={primaryAction.onClick}
            >
              {primaryAction.label}
            </Button>
          )}
        </Flex>
      }
      heading={heading}
    >
      <Flex w={{ base: '300px', md: 'auto' }} flexDirection="column">
        <Text fontSize={{ base: '14px', md: 'initial' }} mt={{ md: 25 }}>
          {content}
        </Text>
        <Image
          display={{ base: 'none', md: 'inherit' }}
          src={images.character5}
          width="30%"
          objectFit="contain"
          alignSelf="center"
          mt={35}
        />
      </Flex>
    </ModalWrap>
  )
}

export default ConfirmationModal
