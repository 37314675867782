import { Box, useCheckbox, UseCheckboxProps } from '@chakra-ui/react'
import * as React from 'react'

export type ConnectedCheckboxCardProps = UseCheckboxProps

const ConnectedCheckboxCard: React.FC<ConnectedCheckboxCardProps> = (props) => {
  const { getInputProps, getCheckboxProps } = useCheckbox(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Box as="label" pr={6} pb={{ md: 10, base: 5 }}>
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="2px"
        borderColor="white"
        borderRadius="3xl"
        boxShadow="xl"
        bg="white"
        alignItems="center"
        _checked={{
          borderColor: 'brand.500'
        }}
      >
        {props.children}
      </Box>
    </Box>
  )
}

export default ConnectedCheckboxCard
