import { extendTheme, theme as chakraTheme } from '@chakra-ui/react'
import images from './images'

const theme = extendTheme({
  ...chakraTheme,
  fonts: {
    body: 'Muli, sans-serif',
    mono: 'Muli, sans-serif',
    heading: 'Muli, sans-serif'
  },
  colors: {
    ...chakraTheme.colors,
    text: '#4A4D50',
    primary: '#5BC1A6',
    transparentPrimary: 'rgba(91, 193, 166, 0.2)',
    secondary: '#4B69B2',
    transparentSecondary: '#4B69B21A',
    tertiary: '#EFC95A',
    background: '#E5E5E5',
    primaryHover: {
      bg: 'rgba(91, 193, 166, 0.5)',
      color: '#ffffff'
    },
    error: {
      100: '#FED7D7',
      500: '#EB5757'
    },
    brand: {
      50: '#ECF8F4',
      100: '#C5E9DC',
      200: '#95DBC1',
      300: '#51BB95',
      400: '#58C69D',
      500: '#3EB489',
      600: '#2A7A5C',
      700: '#1F5B45',
      800: '#1E7F5A',
      900: '#0A1E17'
    },
    accent: {
      50: '#fff7db',
      100: '#ffe5ae',
      200: '#fdd57f',
      300: '#fbc44e',
      400: '#fab31f',
      500: '#FAB423',
      600: '#af7700',
      700: '#7e5500',
      800: '#4c3300',
      900: '#1d1000'
    },
    success: {
      50: '#e3fbee',
      100: '#c3ebd7',
      200: '#a0dcbf',
      300: '#7ccda7',
      400: '#59bf8e',
      500: '#40a674',
      600: '#30815a',
      700: '#205c40',
      800: '#0e3825',
      900: '#001509'
    },
    bdazzledBlue: {
      50: '#8395C2',
      100: '#758ABD',
      200: '#687FB6',
      300: '#5A73AE',
      400: '#5069A5',
      500: '#455B8E',
      600: '#FCFCFD',
      700: '#3C4E7C',
      800: '#34466E',
      900: '#2D3D5F'
    },
    silver: {
      50: '#adadad',
      100: '#a3a3a3',
      200: '#999999',
      300: '#8f8f8f8',
      400: '#858585',
      500: '#7a7a7a',
      600: '#707070',
      700: '#666666',
      800: '#5c5c5c',
      900: '#525252'
    },
    gray: {
      ...chakraTheme.colors.gray,
      100: '#F8F8F6'
    }
  },

  boxShadow: '0px 0px 4px 4px rgba(0,0,0,0.4)',
  gridGutter: 1 // rem - taken from Chakra UI space scale https://chakra-ui.com/theme#spacing
})

export { theme, images }
