import { NavItem } from 'navigation'
import React from 'react'
import { AiFillHome } from 'react-icons/ai'
import { BsCreditCard2BackFill, BsFillBarChartFill, BsFillQuestionCircleFill } from 'react-icons/bs'
import { FaBell, FaUser } from 'react-icons/fa'

export const NAV_ITEMS: NavItem[] = [
  {
    to: '/auth/market-place',
    icon: <AiFillHome size={20} />,
    title: 'Market Place'
  },
  {
    to: '/auth/my-dashboard',
    icon: <BsFillBarChartFill size={20} />,
    title: 'My Dashboard'
  },
  {
    title: 'My Wallet',
    to: '/auth/my-wallet',
    icon: <BsCreditCard2BackFill size={20} />
  },
  {
    to: '/auth/profile',
    title: 'My Profile',
    icon: <FaUser size={20} />
  },
  {
    to: '/auth/notifications',
    title: 'Notifications',
    icon: <FaBell size={20} />
  },
  {
    to: '/auth/help-and-support',
    title: 'Help & Support',
    icon: <BsFillQuestionCircleFill size={20} />
  }
]
