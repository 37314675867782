import axios, { AxiosResponse } from 'axios'
import { fetchJwt } from '.'
import { UploadFile, UsersPermissionsUser, WelcomeSlides } from '../generated/graphql'

const BASE = process.env.REACT_APP_API_HOST || ''

export type StrapiLoginPayload = {
  jwt: string
  user: UsersPermissionsUser
}

export type RegisterInput = {
  username: string
  firstName: string
  lastName: string
  citizenship: string
  dateOfBirth: string
  phoneNumber: string
  email: string
  password: string
}

const forgotPassword = async (email: string): Promise<AxiosResponse<any>> => {
  try {
    return await axios.post(`${BASE}/auth/forgot-password`, {
      email
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

const resetPassword = async (
  code: string,
  password: string,
  email: string
): Promise<AxiosResponse<any>> => {
  try {
    return await axios.post(`${BASE}/auth/reset-password`, {
      code,
      password,
      email
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

const fetchResetPasswordUserEmail = async (code: string): Promise<{ data: { email: string } }> => {
  try {
    return await axios.get(`${BASE}/auth/get-reset-password-user-email/${code}`)
  } catch (error) {
    return Promise.reject(error)
  }
}

const login = async (
  email: string,
  password: string
): Promise<AxiosResponse<StrapiLoginPayload>> => {
  try {
    return await axios.post(`${BASE}/auth/local`, {
      email,
      password
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

const register = async ({
  username,
  firstName,
  citizenship,
  dateOfBirth,
  lastName,
  phoneNumber,
  email,
  password
}: RegisterInput): Promise<AxiosResponse<StrapiLoginPayload>> => {
  try {
    return await axios.post(`${BASE}/auth/local/register`, {
      firstName,
      lastName,
      citizenship,
      dateOfBirth,
      phoneNumber,
      username,
      email,
      password
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

const upload = async (
  file: File,
  onUploadProgress?: (progressEvent: ProgressEvent, file: File) => void
): Promise<AxiosResponse<UploadFile[]>> => {
  const formData = new FormData()
  formData.append('files', file)
  try {
    return await axios.post(`${BASE}/upload`, formData, {
      onUploadProgress: (event) => onUploadProgress && onUploadProgress(event, file),
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${fetchJwt()}`
      }
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

const socialAuth = async (
  provider: string,
  accessToken: string
): Promise<AxiosResponse<StrapiLoginPayload>> => {
  try {
    return await axios.get(`${BASE}/auth/${provider}/callback${accessToken}`)
  } catch (error) {
    return Promise.reject(error)
  }
}

const getWelcomeSlides = async (): Promise<AxiosResponse<WelcomeSlides>> => {
  try {
    return await axios.get(`${BASE}/getWelcomeSlides`)
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  forgotPassword,
  resetPassword,
  login,
  register,
  upload,
  socialAuth,
  getWelcomeSlides,
  fetchResetPasswordUserEmail
}
