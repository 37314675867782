import { Button, Flex, Image, Spacer, Text } from '@chakra-ui/react'
import * as React from 'react'
import { HiPlusSm } from 'react-icons/hi'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { images } from 'theme'

const notePublished = {
  title: 'Your Notes Published',
  image: images['manLeft'],
  imageAlt: 'Man with a page'
}
const createNote = {
  title: 'Create a New Note',
  image: images['manTablet'],
  imageAlt: 'man with a tablet'
}

type NoteCardsButtonProps = {
  title: string
  subtitle?: string
  image: string
}

const NoteCardsButton: React.FC<NoteCardsButtonProps> = ({ title, subtitle, image }) => {
  return (
    <Flex
      justifyContent="space-between"
      borderRadius="3xl"
      shadow="xl"
      bg="white"
      borderColor="white"
      my={2}
      height={200}
      width={300}
      overflow="hidden"
      px={5}
      pt={5}
    >
      <Flex flexDirection="column" pr={2} flex={0.8}>
        <Text fontWeight="bold" mb={2} textAlign="left">
          {title}
        </Text>
        <Text mb={2} textAlign="left">
          {subtitle}
        </Text>
      </Flex>
      <Flex alignItems="flex-end" pt={2} flex={0.8} justifyContent="flex-end">
        <Image
          height="80%"
          src={image}
          fallback={<Skeleton count={1} height={100} width={100} style={{ marginBottom: 10 }} />}
        />
      </Flex>
    </Flex>
  )
}

type NoteCardsProps = {
  myNoteCount: number
  onViewNow: () => void
  onCreate: () => void
}

const NoteCards: React.FC<NoteCardsProps> = ({ myNoteCount, onCreate }) => {
  return (
    <Flex flexDirection="row">
      <Flex flexDirection="column">
        <NoteCardsButton title={notePublished.title} image={notePublished.image} />
        <Flex flexDirection="row" mt="-60px" ml="20px">
          <Button variant="none" colorScheme="brand">
            <Text fontSize="2xl">{myNoteCount}</Text>
          </Button>
        </Flex>
      </Flex>

      <Flex flexDirection="column" mx={5}>
        <NoteCardsButton title={createNote.title} image={createNote.image} />
        <Flex flexDirection="row" mt="-10px" ml="20px">
          <Button
            colorScheme="brand"
            borderRadius={20}
            fontWeight="light"
            mt="-50px"
            leftIcon={<HiPlusSm />}
            onClick={() => onCreate()}
          >
            Create
          </Button>
        </Flex>
      </Flex>
      <Spacer />
    </Flex>
  )
}

export default NoteCards
