import { Flex, FormControl, FormLabel, Text, Textarea, TextareaProps } from '@chakra-ui/react'
import { useField } from 'formik'
import * as React from 'react'
import { LabelProps } from '../styles'

export type ConnectedTextareaProps = LabelProps &
  TextareaProps & {
    label?: string
    name: string
    showError?: boolean
    placeholder?: string
    inputStyles?: string
  }

const ConnectedTextarea: React.FC<ConnectedTextareaProps> = ({
  label,
  showError,
  placeholder,
  inputStyles,
  ...rest
}) => {
  const [field, meta] = useField(rest.name)
  const isInvalid = meta.touched && meta.error

  return (
    <Flex flexDirection="column" width="100%" mr={rest.mr} ml={rest.ml} mt={rest.mt} mb={rest.mb}>
      <FormControl>
        {label && (
          <FormLabel
            htmlFor={field.name}
            fontWeight="600"
            fontSize={{ base: '14px', md: 'unset' }}
            color="text"
          >
            {label}
          </FormLabel>
        )}
        <Textarea
          focusBorderColor="accent.500"
          {...field}
          id={field.name}
          {...inputStyles}
          {...rest}
          placeholder={placeholder || ''}
          variant="filled"
          bg={isInvalid ? 'error.100' : rest.bg}
          color={isInvalid ? 'error.500' : 'text'}
          borderRadius="xl"
          fontColor="black"
          overflow="hidden"
        />
        {showError && isInvalid ? (
          <Text color="red.500" textAlign={{ md: 'right' }}>
            {meta.error}
          </Text>
        ) : null}
      </FormControl>
    </Flex>
  )
}

export default ConnectedTextarea

ConnectedTextarea.defaultProps = {
  mb: 2,
  showError: true,
  bg: 'background'
}
