import { ApolloProvider } from '@apollo/react-hooks'
import { ChakraProvider, CSSReset } from '@chakra-ui/react'
import { css, Global } from '@emotion/react'
import React from 'react'
import 'react-dates/initialize'
import ReactDOM from 'react-dom'
import { Helmet } from 'react-helmet'
import { RecoilRoot } from 'recoil'
import client from './apollo'
import App from './App'
import { APP_NAME } from './constants'
import { AppProvider, AuthProvider, NotificationsProvider, TransactionsProvider } from './context'
import { theme } from './theme'

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <ApolloProvider client={client}>
        <ChakraProvider theme={theme}>
          <AuthProvider>
            <NotificationsProvider>
              <TransactionsProvider>
                <Helmet titleTemplate={`${APP_NAME} | %s`} />
                <AppProvider>
                  <CSSReset />
                  <Global
                    styles={css`
                      * {
                        font-family: ${theme.fonts.body};
                      }
                    `}
                  />
                  <App />
                </AppProvider>
              </TransactionsProvider>
            </NotificationsProvider>
          </AuthProvider>
        </ChakraProvider>
      </ApolloProvider>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root')
)
