import { lazy } from 'react'
import { PrivateRouteObject, SHARED_ROUTES } from '../../../navigation/routes'

const Profile = lazy(() => import('containers/Investor/Profile'))
const MyTransactions = lazy(() => import('containers/Investor/MyTransactions'))
const InvestorOnboarding = lazy(() => import('containers/InvestorOnboarding'))
const MainOnboarding = lazy(() => import('containers/RoleSelect'))
const MarketPlace = lazy(() => import('containers/Investor/MarketPlace'))
const CPInDepthView = lazy(() => import('containers/Investor/NoteInDepthView'))
const CorporateOnboarding = lazy(() => import('containers/CorporateOnboarding'))
const Dashboard = lazy(() => import('containers/Investor/Dashboard'))
const Notifications = lazy(() => import('containers/RegulatoryBody/Notifications'))

export const INVESTOR_ROUTES: PrivateRouteObject[] = [
  {
    exact: true,
    title: 'Market Place',
    path: '/auth/market-place',
    component: MarketPlace
  },
  {
    exact: true,
    title: 'Market Place',
    path: '/auth/market-place/:masterNote/:id/:view',
    component: CPInDepthView
  },
  {
    exact: true,
    title: 'My Profile',
    path: '/auth/profile',
    component: Profile
  },
  {
    exact: true,
    title: 'My Dashboard',
    path: '/auth/my-dashboard',
    component: Dashboard
  },
  {
    exact: true,
    title: 'My Dashboard',
    path: '/auth/my-dashboard/:companyName/:id/:view',
    component: CPInDepthView
  },
  {
    exact: true,
    title: 'My Transactions',
    path: '/auth/my-wallet',
    component: MyTransactions
  },
  {
    exact: true,
    title: 'Corporate Onboarding',
    path: '/auth/corporate-investor-onboarding',
    component: CorporateOnboarding,
    hideSideBar: true
  },
  {
    exact: true,
    title: 'Notifications',
    path: '/auth/notifications',
    component: Notifications
  },
  {
    exact: true,
    title: 'Investor Onboarding',
    path: '/auth/investor-onboarding',
    component: InvestorOnboarding,
    hideSideBar: true
  },
  {
    exact: true,
    title: 'Main Onboarding',
    path: '/auth/main-onboarding',
    component: MainOnboarding,
    hideSideBar: true
  },
  ...SHARED_ROUTES
]
