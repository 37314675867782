import {
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  Text
} from '@chakra-ui/react'
import { useField } from 'formik'
import * as React from 'react'
import { RiEyeCloseLine, RiEyeFill } from 'react-icons/ri'
import { LabelProps } from '../styles'

export type ConnectedFormGroupProps = LabelProps &
  InputProps & {
    label?: string
    name: string
    inputStyle?: InputProps
    showError?: boolean
    placeholder?: string
  }

const ConnectedFormGroup: React.FC<ConnectedFormGroupProps> = ({
  label,
  type,
  showError,
  inputStyle,
  placeholder,
  ...rest
}) => {
  const [field, meta] = useField(rest.name)
  const [show, setShow] = React.useState(false)
  const handleClick = () => setShow(!show)

  const isInvalid = meta.touched && meta.error

  return (
    <Flex
      flexDirection="column"
      width={rest.width}
      mr={rest.mr}
      ml={rest.ml}
      mt={rest.mt}
      mb={rest.mb}
    >
      <FormControl>
        {label && field.value ? (
          <FormLabel htmlFor={field.name} fontWeight="600" color="text">
            {label}
          </FormLabel>
        ) : null}
        <InputGroup size="md">
          <Input
            focusBorderColor="accent.500"
            type={show ? 'text' : type}
            {...field}
            id={field.name}
            {...inputStyle}
            placeholder={placeholder || ''}
            variant="filled"
            bg={isInvalid ? 'error.100' : 'background'}
            color={isInvalid ? 'error.500' : 'text'}
          />
          <InputRightElement>
            <Icon
              size="20px"
              onClick={handleClick}
              as={show ? RiEyeFill : RiEyeCloseLine}
              color={isInvalid ? 'error.500' : 'text'}
              cursor="pointer"
            />
          </InputRightElement>
        </InputGroup>
        {showError && isInvalid ? (
          <Text color="red.500" textAlign="left">
            {meta.error}
          </Text>
        ) : null}
      </FormControl>
    </Flex>
  )
}

export default ConnectedFormGroup

ConnectedFormGroup.defaultProps = {
  mb: 2,
  type: 'password',
  showError: false,
  inputStyle: {
    borderRadius: 32
  }
}
