import { Button, Flex, FlexProps, Image, Text } from '@chakra-ui/react'
import Waves from 'assets/images/waves'
import {
  Enum_Commercialpapers_Currentstep,
  Enum_Masternote_Currentstep,
  Enum_Userspermissionsuser_Onboardingstatus
} from 'generated/graphql'
import React, { ReactElement } from 'react'
import { images } from 'theme'

export type TabsData = {
  step?: number
  title: string
  route: string
  status?:
    | Enum_Userspermissionsuser_Onboardingstatus
    | Enum_Commercialpapers_Currentstep
    | Enum_Masternote_Currentstep
  component?: ReactElement
}

type StyledTabMenuProps = {
  containerStyles?: FlexProps
  currentIndex: number
  data: TabsData[]
  onSaveAndExit?: () => void
}

const StyledTabMenu: React.FC<StyledTabMenuProps> = ({
  containerStyles,
  currentIndex,
  data,
  onSaveAndExit
}) => {
  return (
    <Flex
      width="20%"
      pt={4}
      display={{ base: 'none', md: 'flex' }}
      borderRightWidth={2}
      borderColor="text"
      {...containerStyles}
      flexDirection="column"
      alignItems="flex-start"
      maxHeight="100%"
      minH="100vh"
    >
      <Image pl={4} src={images.logo} height="80px" objectFit="contain" />
      <Flex pl={4} flex={1} py={20} flexDirection="column" width="100%">
        {data.map((el, index) => {
          const isSelected = currentIndex === index
          return (
            <Flex
              key={index}
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="center"
              width="100%"
              p={5}
              position="relative"
              cursor={isSelected ? 'pointer' : 'text'}
              opacity={isSelected ? 1 : 0.5}
              transition="all 0.3s ease"
            >
              <Text fontWeight={isSelected ? 'semibold' : 'light'} textAlign="right">
                {el.title}
              </Text>
              <Flex
                opacity={isSelected ? 1 : 0}
                size={10}
                borderRadius="full"
                borderWidth={2}
                height="20px"
                width="20px"
                borderColor="secondary"
                position="absolute"
                top={6}
                bottom={0}
                right={-3}
                overflow="hidden"
              >
                <Flex
                  height="100%"
                  width="100%"
                  bg="secondary"
                  borderRadius="full"
                  borderWidth={2}
                  borderColor="background"
                />
              </Flex>
            </Flex>
          )
        })}
      </Flex>
      <Flex width="100%" alignItems="flex-end" justifyContent="flex-end" position="relative">
        <Waves width="100%" height="200px" style={{ right: undefined }} />
        {onSaveAndExit && (
          <Button
            variant="outline"
            color="background"
            mr={5}
            mb={5}
            borderRadius={20}
            onClick={onSaveAndExit}
          >
            Save & Exit
          </Button>
        )}
      </Flex>
    </Flex>
  )
}

export default StyledTabMenu
