import { Button, Flex, Image, Modal, ModalContent, Text, useDisclosure } from '@chakra-ui/react'
import { useField } from 'formik'
import React from 'react'
import Webcam from 'react-webcam'

type ConnectedWebcamProps = {
  name: string
  label?: string
}

const ConnectedWebcam: React.FC<ConnectedWebcamProps> = ({ name, label }) => {
  const ref = React.useRef(null)
  const [field, meta, helpers] = useField(name)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const onCapture = React.useCallback(() => {
    // eslint-disable-next-line
    // @ts-ignore
    const imageSrc = ref?.current?.getScreenshot()
    helpers.setValue(imageSrc)
    onClose()
  }, [ref])

  const isInvalid = meta.touched && meta.error

  return (
    <Flex flexDirection="column" mb={5}>
      {label ? (
        <Text color={isInvalid ? 'error.500' : 'text'} fontWeight={600} mb={5}>
          {label}
        </Text>
      ) : null}
      <Flex margin="0 auto" flexDirection="column">
        {!field.value ? (
          <Button onClick={onOpen}>Open Webcam</Button>
        ) : (
          <>
            <Image src={field.value} boxSize="200px" borderRadius="full" objectFit="fill" />
            <Button onClick={onOpen} mt={5} color="white" bg="secondary">
              Retake
            </Button>
          </>
        )}
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalContent bg="transparent">
          <Flex borderRadius={10} overflow="hidden">
            <Webcam ref={ref} mirrored screenshotFormat="image/jpeg" />
          </Flex>
          <Button onClick={onCapture} my={2} color="white" bg="secondary">
            Capture
          </Button>
          <Button onClick={onClose} color="white" bg="red.400">
            Cancel
          </Button>
        </ModalContent>
      </Modal>
    </Flex>
  )
}

ConnectedWebcam.defaultProps = {
  label: 'Open Webcam'
}

export default ConnectedWebcam
