// v4 causes dynamic image imports to be objects: https://github.com/facebook/create-react-app/issues/9992
export default {
  bg: require('../assets/images/core-bg.jpg')?.default,
  logo: require('../assets/images/logo.svg')?.default,
  404: require('../assets/images/404.svg')?.default,
  noData: require('../assets/images/no-data.png')?.default,
  splashScreen: require('../assets/images/logo.svg')?.default,
  corporate: require('../assets/images/Character4.svg')?.default,
  investor: require('../assets/images/Character3.svg')?.default,
  onboarding1: require('../assets/images/OnboardingWaves1.png')?.default,
  bgWavesPNG: require('../assets/images/bgWaves.png').default,
  notes: require('../assets/images/notes.png').default,
  woman: require('../assets/images/login.svg').default,
  man: require('../assets/images/register.svg').default,
  man2: require('../assets/images/man2.png').default,
  character5: require('../assets/images/complete.svg').default,
  manTablet: require('../assets/images/Property1Character14.svg').default,
  manLeft: require('../assets/images/Man2.svg').default,
  ladyNote: require('../assets/images/WOMAN.svg').default,
  newNote: require('../assets/images/new.svg').default,
  downloadIcon: require('../assets/images/DownloadSimple.svg').default,
  editIcon: require('../assets/images/NotePencil.svg').default,
  agreementPDF: require('../assets/investorOnboardingAgreement.pdf').default,
  sendNote: require('../assets/images/Vectornote.svg').default,
  clock: require('../assets/images/Clock.svg').default,
  manFlipped: require('../assets/images/manFlipped.svg').default,
  ladyMic: require('../assets/images/Character6.svg').defaults,
  alert: require('../assets/images/alert.svg').default,
  waveIcon: require('../assets/images/Mask_Group.svg').default,
  modalWave: require('../assets/images/modal-wave.svg').default
}
