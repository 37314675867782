import { Flex, FlexProps, Image, Spinner, Text, useToast } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { useField } from 'formik'
import { UploadFile } from 'generated/graphql'
import { get } from 'lodash'
import React from 'react'
import { RiImageAddLine } from 'react-icons/ri'
import strapiHelpers from 'utils/strapiHelpers'
import { ERROR_TOAST, SUCCESS_TOAST } from '../../../constants'

type ConnectedImageProps = {
  name: string
  label?: string
  containerStyle?: FlexProps
  imageContainer?: FlexProps | any
  labelPlacing?: 'TOP' | 'BOTTOM'
  onUploadComplete?: (image: UploadFile) => void
  isDisabled?: boolean
}

const HiddenInput = styled.input`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  zindex: 10;
  opacity: 0;
`

const ConnectedImage: React.FC<ConnectedImageProps> = ({
  name,
  label,
  containerStyle,
  labelPlacing,
  onUploadComplete,
  imageContainer,
  isDisabled
}) => {
  const [field, meta, helpers] = useField(name)
  const toast = useToast()
  const [uploading, setUploading] = React.useState(false)
  const inputRef = React.createRef<HTMLInputElement>()

  const handleUpload = async (file: File) => {
    setUploading(true)
    try {
      const strapiFile = await strapiHelpers.upload(file)
      toast({ description: 'Image has been uploaded.', ...SUCCESS_TOAST })
      helpers.setValue(strapiFile.data[0])
      if (onUploadComplete) {
        onUploadComplete(strapiFile.data[0])
      }
      setUploading(false)
    } catch (e) {
      setUploading(false)
      toast({
        description: 'Something went wrong while uploading your photo.',
        ...ERROR_TOAST
      })
    }
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (files?.length) {
      handleUpload(files[0])
    }
  }

  const localUrl = React.useMemo(() => get(field, 'value.url'), [field])

  const isInvalid = meta.error && meta.touched

  return (
    <Flex
      flexDirection={labelPlacing === 'BOTTOM' ? 'column' : 'column-reverse'}
      {...containerStyle}
      alignItems="center"
    >
      <Flex
        _hover={{ cursor: uploading || isDisabled ? 'not-allowed' : 'pointer' }}
        borderWidth={1}
        bg={isInvalid ? 'error.100' : 'transparent'}
        borderColor={isInvalid ? 'error.500' : 'text'}
        borderRadius="full"
        overflow="hidden"
        position="relative"
        {...imageContainer}
      >
        {localUrl ? (
          <Image src={localUrl} boxSize="100%" p={1} borderRadius="full" objectFit="contain" />
        ) : (
          <Flex
            bg="tertiary"
            flex={1}
            borderRadius="full"
            m={1}
            justifyContent="center"
            alignItems="center"
          >
            {!uploading ? (
              <RiImageAddLine color="white" size={30} />
            ) : (
              <Spinner color="background" />
            )}
          </Flex>
        )}
        {!uploading && !isDisabled ? (
          <HiddenInput
            ref={inputRef}
            onChange={onChange}
            type="file"
            multiple
            name={name}
            id={name}
            accept="image/*"
          />
        ) : null}
      </Flex>
      {label || uploading ? (
        <Flex flexDirection="row" alignItems="center">
          <Text fontWeight="600" color="text">
            {uploading ? 'Uploading Image...' : label}
          </Text>
        </Flex>
      ) : null}
    </Flex>
  )
}

export default React.memo(ConnectedImage)

ConnectedImage.defaultProps = {
  containerStyle: {
    mb: 2
  },
  labelPlacing: 'BOTTOM',
  imageContainer: {
    width: '120px',
    height: '120px'
  }
}
